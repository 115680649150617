import { useEffect, useState } from "react";

export interface SportDetailsInterface {
    back1: number;
    back2: number;
    back3: number;
    eid: number;
    eventName: string;
    f: boolean;
    gameId: number;
    ifb: boolean;
    inPlay: boolean;
    lay1: number;
    lay2: number;
    lay3: number;
    m1: boolean;
    marketId: string;
    openDate: string;
    p: boolean;
    pin: boolean;
    _id: string;
  }

function OddsDammyData (props:any) {
    const [sportDetail, setSportDetail] = useState<SportDetailsInterface>({
        back1: 0,
        back2: 0,
        back3: 0,
        eid: 0,
        eventName: "hi v hello",
        f: false,
        gameId: 0,
        ifb: true,
        inPlay: false,
        lay1: 0,
        lay2: 0,
        lay3: 0,
        m1: true,
        marketId: "",
        openDate: "",
        p: false,
        pin: true,
        _id: ""
    });
    const {detailSport} = props;
    const sportName = localStorage.getItem('sportsName');
    let sportsData = localStorage.getItem("sportsData");

  useEffect(()=>{
    if(sportsData){
      console.log("sportsData :: ", JSON.parse(sportsData));
      const sportsDataTemp = JSON.parse(sportsData)
      setSportDetail(sportsDataTemp)
    }
    // let ff = sportDetail[`back${0+1}`]
  },[])
    return(
        <>
        {!detailSport?.page &&
                    <>
                        <div className="balance-label-detail position-relative text-start d-flex justify-content-between exchange-label">
                            <span>Exchange</span>
                            <div className="text-yellow balance-label-inner">
                                <span>Matched</span>
                                <strong className="px-2">PBU 1111111</strong>
                            </div>
                        </div>
                        <div className="match-odds-sec">
                            <div className="bg-yellow d-flex match-odd-header-outer ">
                                <div className=" d-flex align-items-center match-odd-header-text match-odd-header" >
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="20" width="20" xmlns="http://www.w3.org/2000/svg" >
                                        <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z"></path>
                                    </svg>
                                    Match Odds
                                </div>
                            </div>
                            <div className="match-odd-table bg-white p-3 px-2 py-1">
                                <div className="d-flex back-lay-head justify-content-end ms-auto pb-1"><span className="text-center w-100">Back</span><span className="text-center w-100">Lay</span></div>
                                <ul className="mb-2 rounded position-relative p-0">
                                    {[0,1].map((item:any, i) => {
                                        return (
                                            <>
                                                <li key={i}>
                                                    <h2>
                                                        {sportName?.split(' v ')[i]}
                                                    </h2>
                                                    <div className="lay-back-table ">
                                                        <div id="back_odds_0" className='back-active  back spark-back'>
                                                            {/* <span className='single-disabled' > */}
                                                            <span className='' >
                                                                {/* <span className="disabled"> </span> */}
                                                                <strong>{i === 0 ? sportDetail?.back1 : sportDetail?.back2}</strong>
                                                                <span className="d-block">0</span>
                                                            </span>
                                                        </div>

                                                        <div id="lay_odds_0" className='lay-active lay spark-lay' >
                                                            {/* <span className='single-disabled' > */}
                                                            <span className='' >
                                                                {/* <span className="disabled"> </span> */}
                                                                <strong>{i === 0 ? sportDetail?.lay1 : sportDetail?.lay2}</strong>
                                                                <span className="d-block">0</span>
                                                            </span>
                                                        </div>

                                                    </div>
                                                </li>
                                            </>
                                        )
                                    })}
                                </ul>

                                <div className="market-depth-sec d-flex justify-content-end pt-1 justify-content-between items-content-center">
                                    <button className="market-depth-sec-btn inline-flex justify-center items-center rounded bg-secondary text-global-font-on-dark px-3px py-1.5">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="164.000000pt" height="170.000000pt" viewBox="0 0 164.000000 170.000000" preserveAspectRatio="xMidYMid meet">

                                                <g transform="translate(0.000000,170.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                                                    <path d="M840 1065 l0 -515 130 0 130 0 0 515 0 515 -130 0 -130 0 0 -515z" />
                                                    <path d="M160 975 l0 -425 125 0 125 0 0 425 0 425 -125 0 -125 0 0 -425z" />
                                                    <path d="M1180 890 l0 -340 130 0 130 0 0 340 0 340 -130 0 -130 0 0 -340z" />
                                                    <path d="M500 805 l0 -255 130 0 130 0 0 255 0 255 -130 0 -130 0 0 -255z" />
                                                    <path d="M160 250 l0 -130 685 0 685 0 0 130 0 130 -685 0 -685 0 0 -130z" />

                                                </g>
                                            </svg>

                                        </div>
                                        <span className="px-3px text-11"> Market Depth </span>
                                    </button>
                                    <div className="d-flex align-items-center min-max">
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M214.059 377.941H168V134.059h46.059c21.382 0 32.09-25.851 16.971-40.971L144.971 7.029c-9.373-9.373-24.568-9.373-33.941 0L24.971 93.088c-15.119 15.119-4.411 40.971 16.971 40.971H88v243.882H41.941c-21.382 0-32.09 25.851-16.971 40.971l86.059 86.059c9.373 9.373 24.568 9.373 33.941 0l86.059-86.059c15.12-15.119 4.412-40.971-16.97-40.971z"></path>
                                        </svg>
                                        <span>min/max</span><strong>1/10,000</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }

        </>
    )
}

export default OddsDammyData