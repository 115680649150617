import React, { lazy, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie';
import { ADMIN_API, USER_API } from './common/common';
import { Logout } from './common/Funcation';
import ChangePassword from './pages/ChangePassword';
import DepositWithdraw from './pages/DepositWithdraw';
import Maintenance from './pages/Maintenance'
import MultiMarket from './pages/MultiMarket/MultiMarket';
import MyAccount from './pages/MyAccount';
import MyAccountMobile from './pages/MyAccountMobile ';
import Cricket from './pages/Sport/Cricket';
import Soccer from './pages/Sport/Soccer';
import Tennis from './pages/Sport/Tennis';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { getApi, postApi, postApiAdmin, sendEvent, socketConnect } from './service';
import Sports from './pages/Inplay/Sports';
import Casino from './pages/Inplay/Casino';
import Leagues from './pages/Inplay/Leagues';
import BalanceOverview from './components/Profile/BalanceOverview';
import AccountStatementNew from './components/Profile/AccountStatementNew';
import Setting from './components/Profile/Setting';
import P2Ptransfer from './components/Profile/P2Ptransfer';
import BetHistory from './components/Profile/BetHistory.jsx';
import CurrentBet from './components/Profile/CurrentBet';
import ProfitLoss from './components/Profile/ProfitLoss.jsx';
import ActivityLogNew from './components/Profile/ActivityLogNew';
import MyProfile from './components/Profile/ActivityLogNew copy';
import P2PtransferLog from './components/Profile/P2PtransferLog';
import PageNotFound from './pages/PageNotFound';
const envDomain = process.env.REACT_APP_DOMAIN
// import Home from './pages/Home'
const Home = lazy(() => import('./pages/Home'));
const Login = lazy(() => import('./pages/Login'));


const cookies = new Cookies()
const Router = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const HeaderData = useSelector((e: any) => e.Header);

    useEffect(() => {
        const authToken = cookies.get('skyTokenFront')
        if (authToken) {
            getHeaderDetails()
            getMarketListDetails()
        }
        if (authToken && HeaderData) {
            setTimeout(() => {
                sendEvent('SIGN_UP', { userId: HeaderData?._id })
            }, 500);
            setTimeout(() => {
                sendEvent('VERIFY_TOKEN', { userId: HeaderData?._id, token: authToken })
                sendEvent('UPDATE_USER_BALANCE', { userId: HeaderData?._id })
            }, 700);

            getHeaderDetails()

        }
        socketConnect(dispatch, (flag: any) => {
            if (flag) {
                setTimeout(() => {
                    sendEvent('GET_LIVE_MATCH_COUNT_FOR_VELKI', {})
                }, 500);
            }
        })
        getDomainDetails()
        return () => {

        }
    }, [])

    const getHeaderDetails = async () => {
        let data = {
            api: USER_API.GET_PROFILE,
            value: {
            },
        }
        await getApi(data).then(function (response) {
            dispatch({ type: 'HEADER_DETAILS', payload: response.data.data })
            if (response.data.data.newPassword && window.location.pathname !== '/change/password') {
                window.location.href = '/change/password'
            }
        }).catch(err => {
            if (err.response.data.statusCode === 401) {
                Logout()
                window.open('/login')
            }
        })
    }
    const getDomainDetails = async () => {
        let data = {
            api: ADMIN_API.SETTING.WEBSITE.GET_SITE,
            value: {
                domain: envDomain ? envDomain : window.location.hostname
            },
        }
        await postApiAdmin(data).then(function (response) {
            if (response.data.data) {
                dispatch({ type: 'DOMAIN_DETAILS', payload: response.data.data })
            }
            else {
                navigate('/404')
            }
        }).catch(err => {
            if (err.response.data.statusCode === 401) {
                Logout()
                navigate('/404')
                // window.open('/login')
            }else{
                navigate('/404')
            }
        })
    }
    const getMarketListDetails = async () => {
        let data = {
            api: USER_API.MARKET_LIST,
            value: {
            },
        }
        await getApi(data).then(function (response) {
            dispatch({ type: 'MARKET_LIST_DETAILS', payload: response.data.data })
        }).catch(err => {
            if (err.response.data.statusCode === 401) {
                Logout()
                window.open('/login')
            }
        })
    }
    return (
        <>
            <Routes>
                <Route path={`/404`} element={<PageNotFound />} />
                <Route path={`/`} element={<Home />} />
                <Route path={`/login`} element={<PublicRoute><Login /></PublicRoute>} />
                <Route path={`/profile`} element={<PrivateRoute><MyAccount /></PrivateRoute>} >
                    <Route path={`:tab/`} element={<PrivateRoute><MyAccount /></PrivateRoute>} />
                    <Route path={`/profile`} element={<PrivateRoute><MyAccount /></PrivateRoute>} />
                </Route>
                <Route path={`/user/:tab/`} element={<PrivateRoute><MyAccount /></PrivateRoute>} />
                <Route path={`/myAccount`} element={<PrivateRoute><MyAccountMobile /></PrivateRoute>} />
                <Route path={`/d-w`} element={<PrivateRoute><DepositWithdraw /></PrivateRoute>} />
                <Route path={`/change/password`} element={<><ChangePassword /></>} />
                <Route path={`/maintenance`} element={<PublicRoute><Maintenance /></PublicRoute>} />


                <Route path={`/sports`} element={<Sports />} />
                <Route path={`/casino`} element={<Casino />} />
                <Route path={`/leagues`} element={<Leagues />} />
                <Route path={`/balance-overview`} element={<BalanceOverview />} />
                <Route path={`/account-statement`} element={<AccountStatementNew />} />
                <Route path={`/bets-history`} element={<BetHistory />} />
                <Route path={`/current-bets`} element={<CurrentBet />} />
                <Route path={`/profit-and-loss`} element={<ProfitLoss />} />
                <Route path={`/active-log`} element={<ActivityLogNew />} />
                <Route path={`/my-profile`} element={<MyProfile />} />
                <Route path={`/p2p-transfer`} element={<P2Ptransfer />} />
                <Route path={`/p2p-transfer-log`} element={<P2PtransferLog />} />
                <Route path={`/setting`} element={<Setting />} />



                <Route path={`/cricket`} element={<Cricket />} />
                <Route path={`/soccer`} element={<Soccer />} />
                <Route path={`/tennis`} element={<Tennis />} />
                <Route path={`/multimarket/:eventId/:marketId`} element={<MultiMarket />} />
            </Routes>
        </>
    )
}

export default Router